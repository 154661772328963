import React, { Fragment, useEffect, useState, Suspense } from 'react';
import dynamic from 'next/dynamic';
import { Parallax } from 'react-scroll-parallax';
import { Waypoint } from 'react-waypoint';
import {DeviceType} from '../../adapters/helpers/DeviceTypes';
import { getConfigurationBooleanValue, getConfigurationValue } from '../../adapters/helpers/ContentBlockConfiguration';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import Block from '../../adapters/helpers/Block';
import PropTypes from 'prop-types'
import { Constants, ContentConstants } from '../../adapters/helpers/Constants';
import {Label} from '../Label/Label';
import { getRGBFromHexColor, handleGAEvents, isIE, stripHtml } from '../../adapters/helpers/Utils';
import DOMPurify from 'isomorphic-dompurify'
import Loader from '../../adapters/helpers/Loader';
const CashbackForm  = dynamic(() =>  import('../CashbackForm/CashbackForm'));
const SucessModal  = dynamic(() =>  import('../CashbackForm/SucessModal'));
const BodyText = dynamic(() => import('../BodyText/BodyText'));
const Button = dynamic(() => import('../Button/Button'));
const Disclaimer = dynamic(() => import('../Disclaimer/Disclaimer'));
const Heading = dynamic(() => import('../Heading/Heading'));
const Icon = dynamic(() => import('../Icon/Icon'));
const Image = dynamic(() => import('../Image/Image'));
const ObLink = dynamic(() => import('../ObLink/ObLink'));
const BackgroundWrapper = dynamic(() => import('./BackgroundWrapper'));
const MarkdownText = dynamic(() => import('../MarkdownText/MarkdownText'))

/**
 *
 * Supported Classes:
 *  By default the background is white and the text color is primary-grey.
 *  The CTA is a Button by default
 *
 *  - background-primary-grey : change the background to primary grey
 *  - background-secondary-grey : change the background to secondary grey
 *  - background-secondary-off-white : change the background to secondary-off-white
 *  - background-isolate-grey : change the background to isolate grey
 *  - background-primary-blue : change the background to primary-blue
 *  - background-secondary-blue : change the background to secondary-blue
 *
 */

function ContentBlock(props) {
    const {isXSmall,isSmall, isMedium, isXLarge} = props;

    const valueOf = (name) => {
      let value = undefined;
      if (name && block && block.getFieldValue(name) !== undefined) {
          value = block.getFieldValue(name);
      }
      return value;
    };

    const generateContentblockState = () => {
        return {
            availableConfigurations: valueOf(ContentConstants.devicesConfigurations).map(configuration => configuration.fields.deviceType),
            isContentImageAndTextSeparate: valueOf(ContentConstants.devicesConfigurations).map((configuration) => configuration?.fields?.isContentImageAndTextSeparate),
            blockMinHeight: configurationValue(ContentConstants.blockMinHeight, isMedium, isXSmall, isXLarge, ''),
            isRemoveParallax: configurationBooleanValue(ContentConstants.isRemoveParallax,isMedium,isXSmall,isXLarge),
            blockMarginTop: configurationValue(ContentConstants.blockMarginTop, isMedium, isXSmall, isXLarge, ''),
            blockMarginBottom: configurationValue(ContentConstants.blockMarginBottom, isMedium, isXSmall, isXLarge, ''),
            blockMarginRight: configurationValue(ContentConstants.blockMarginRight, isMedium, isXSmall, isXLarge, ''),
            blockMarginLeft: configurationValue(ContentConstants.blockMarginLeft, isMedium, isXSmall, isXLarge, ''),
            blockPadding: configurationValue(ContentConstants.blockPadding, isMedium, isXSmall, isXLarge, ''),
            textAlign: configurationValue(ContentConstants.textAlign, isMedium, isXSmall, isXLarge, ''),
            textContainerWidth: configurationValue(ContentConstants.textContainerWidth, isMedium, isXSmall, isXLarge, ''),
            titleWidth: configurationValue(ContentConstants.titleWidth, isMedium, isXSmall, isXLarge, ''),
            descriptionWidth: configurationValue(ContentConstants.descriptionWidth, isMedium, isXSmall, isXLarge, ''),
            textContainerHorizontalAlignment: configurationValue(ContentConstants.textContainerHorizontalAlignment, isMedium, isXSmall, isXLarge, ''),
            textContainerVerticalAlignment: configurationValue(ContentConstants.textContainerVerticalAlignment, isMedium, isXSmall, isXLarge, ''),
            textContainerOffset: configurationValue(ContentConstants.textContainerOffset, isMedium, isXSmall, isXLarge, ''),
            textContainerMarginRight: configurationValue(ContentConstants.textContainerMarginRight, isMedium, isXSmall, isXLarge, ''),
            textContainerMarginLeft: configurationValue(ContentConstants.textContainerMarginLeft, isMedium, isXSmall, isXLarge, ''),
            textContainerMarginBottom: configurationValue(ContentConstants.textContainerMarginBottom, isMedium, isXSmall, isXLarge, ''),
            mainAsset: configurationValue(ContentConstants.mainAsset, isMedium, isXSmall, isXLarge),
            mainAssetBelowOrAboveText: configurationValue(ContentConstants.mainAssetBelowOrAboveText, isMedium, isXSmall, isXLarge, ''),
            mainAssetMaxWidth: configurationValue(ContentConstants.mainAssetMaxWidth, isMedium, isXSmall, isXLarge, ''),
            mainAssetMaxHeight: configurationValue(ContentConstants.mainAssetMaxHeight, isMedium, isXSmall, isXLarge, ''),
            mainAssetHeight: configurationValue(ContentConstants.mainAssetHeight, isMedium, isXSmall, isXLarge, ''),
            mainAssetOffsetTop: configurationValue(ContentConstants.mainAssetMarginTop, isMedium, isXSmall, isXLarge, ''),
            mainAssetOffsetRight: configurationValue(ContentConstants.mainAssetMarginRight, isMedium, isXSmall, isXLarge,''),
            mainAssetOffsetBottom: configurationValue(ContentConstants.mainAssetOffsetBottom, isMedium, isXSmall, isXLarge, ''),
            mainAssetOffsetLeft: configurationValue(ContentConstants.mainAssetOffsetLeft, isMedium, isXSmall, isXLarge, ''),
            mainAssetScale: configurationValue(ContentConstants.mainAssetScale, isMedium, isXSmall, isXLarge, ''),
            backgroundAsset: configurationValue(ContentConstants.backgroundAsset, isMedium, isXSmall, isXLarge),
            mainAssetHorizontalAlignment: configurationValue(ContentConstants.mainAssetHorizontalAlignment, isMedium, isXSmall, isXLarge),
            mainAssetVerticalAlignment: configurationValue(ContentConstants.mainAssetVerticalAlignment, isMedium, isXSmall, isXLarge),
            backgroundPosition: configurationValue(ContentConstants.backgroundPosition, isMedium, isXSmall, isXLarge, ''),
            sectionOffsetTop: configurationValue(ContentConstants.sectionOffsetTop, isMedium, isXSmall, isXLarge, ''),
            mainAssetParallax: configurationValue(ContentConstants.mainAssetParallax, isMedium, isXSmall, isXLarge, ''),
            mainAssetParallaxSetting: configurationValue(ContentConstants.mainAssetParallaxSetting, isMedium, isXSmall, isXLarge, ''),
            textParallax: configurationValue(ContentConstants.textParallax, isMedium, isXSmall, isXLarge, ''),
            textParallaxSetting: configurationValue(ContentConstants.textParallaxSetting, isMedium, isXSmall, isXLarge, ''),
            textParallaxConfiguration: configurationValue(ContentConstants.textParallaxConfiguration, isMedium, isXSmall, isXLarge, ''),
            assetParallaxConfiguration: configurationValue(ContentConstants.assetParallaxConfiguration, isMedium, isXSmall, isXLarge, ''),
            textShowAnimation: configurationValue(ContentConstants.textShowAnimation, isMedium, isXSmall, isXLarge, ''),
            textFadeIn: configurationBooleanValue(ContentConstants.textFadeIn, isMedium, isXSmall, isXLarge),
            textColor: configurationBooleanValue(ContentConstants.textColor, isMedium, isXSmall, isXLarge),
            respectImageAspectRatio: configurationBooleanValue(ContentConstants.respectImageAspectRatio, isMedium, isXSmall, isXLarge),
            textContainerBackgroundColor: configurationValue(ContentConstants.textContainerBackgroundColor, isMedium, isXSmall, isXLarge),
            textContainerBackgroundOpacity: configurationValue(ContentConstants.textContainerBackgroundOpacity, isMedium, isXSmall, isXLarge),
        };
    };

    const configurationValue = (fieldName, isMedium, isXSmall, isXLarge, defaultValue = false) => {
      let isHomepage = false
      if(props?.className == 'ob-homepage-hero-block' || valueOf('isRemoveParallax') == true){
        isHomepage = true
      }        
        return getConfigurationValue(availableConfigurations,
            valueOf(ContentConstants.devicesConfigurations),
            fieldName,
            isMedium,
            isXSmall,
            isXLarge,
            defaultValue,isHomepage);
    };


    const configurationBooleanValue = (fieldName, isMedium, isXSmall, isXLarge, defaultValue = false) => {
        return getConfigurationBooleanValue(availableConfigurations,
            valueOf(ContentConstants.devicesConfigurations),
            fieldName,
            isMedium,
            isXSmall,
            isXLarge,
            defaultValue);
    };

    let [show, setShow] = useState('')

    const _handleWaypointEnter = () => {
        // Trigger by waypoint enter
        showContentElement();
    };

    const showContentElement = () => {
        // Animation on the text container on the 'onEnter' event
        setShow('show');
    };

    const getTitleFontSize = () => {
        // By default the Title font size is medium.
        let titleFontSize = '';

        switch (valueOf(ContentConstants.titleFontSize)) {
            case ContentConstants.small:
                titleFontSize = 'ob-display-3-xl';
                break;
            case ContentConstants.medium:
                titleFontSize = 'ob-display-2-xl';
                break;
            case ContentConstants.large:
                titleFontSize = 'ob-display-1-xl';
                break;
            default:
                titleFontSize = 'ob-display-2-xl';
        }

        return titleFontSize;
    };

    const getParallaxConfigurationValue = (hasParallax, parallaxConfigurationAttribute, axis) => {
        let value = [0, 0];

        const srcAttr = axis + ContentConstants.Src;
        const destAttr = axis + ContentConstants.Dest;

        if (hasParallax && !isIE()) {
            if (blockState[parallaxConfigurationAttribute].fields[srcAttr] && blockState[parallaxConfigurationAttribute].fields[destAttr]) {
                value = [blockState[parallaxConfigurationAttribute].fields[srcAttr], blockState[parallaxConfigurationAttribute].fields[destAttr]];
            }
        }
        return value;
    };

    const renderDisclaimer = () => {
        return (
            <div className={'ob-contentBlock-disclaimer'}
                 style={{
                     width: blockState.textContainerWidth ? blockState.textContainerWidth : null,
                     textAlign: blockState.textAlign ? blockState.textAlign : null
                 }}
            >
                <Disclaimer
                    whiteText={isWhiteText}
                    document={disclaimerReference}
                />
            </div>
        )
    };

    const block = new Block(props);
    const availableConfigurations = valueOf(ContentConstants.devicesConfigurations).map(configuration => configuration.fields.deviceType);
    const isContentMobile = valueOf(ContentConstants.devicesConfigurations).map((configuration) => {
      return { isContentImageAndTextSeparate: configuration?.fields?.isContentImageAndTextSeparate, deviceType: configuration?.fields?.deviceType }
    })
    const className = valueOf(ContentConstants.classNames);
    const isALink = valueOf(ContentConstants.callToActionIsALink);
    const title = valueOf(ContentConstants.title);
    const titleLevel = valueOf(ContentConstants.titleLevel);
    const blueEyebrow = valueOf(ContentConstants.blueEyebrow);
    const titleFontSize = getTitleFontSize();
    const appStoreImage = valueOf(ContentConstants.appStoreIcon)
    const googlePlayImage = valueOf(ContentConstants.googlePlayIcon)
    const noteText = valueOf(ContentConstants.noteText)
    const disclaimerReference = valueOf(ContentConstants.disclaimerReference);
    const textsImage = valueOf(ContentConstants.textsImage);
    const anchorId = block.getAnchorId();
    const [blockState, setblockState] = useState(generateContentblockState());
    const pauseLabel = Label({label: block.getFieldValue(ContentConstants.pauseLabel)});
    const playLabel = Label({label: block.getFieldValue(ContentConstants.playLabel)});
    const resetLabel = Label({label: block.getFieldValue(ContentConstants.resetLabel)});
    const openTranscriptLabel = Label({label: block.getFieldValue(ContentConstants.openTranscriptLabel)});
    const closeTranscriptLabel = Label({label: block.getFieldValue(ContentConstants.closeTranscriptLabel)});
    const hasDivider = valueOf(ContentConstants.hasDivider);
    const contentOptions = valueOf(ContentConstants.contentOptions);
    const contentOptionClassName = Label({ label: block.getFieldValue(ContentConstants.contentOptionClassName) });
    const isRemoveParallax=blockState.isRemoveParallax

    const backgroundColor = valueOf(ContentConstants.backgroundColor);
    const ctaBackgroundColor = valueOf(ContentConstants.ctaBackgroundColor);
    const CtalabelId = valueOf(ContentConstants.CtalabelId)
    const dataActionDetailPresent = block.getFieldValue(ContentConstants.dataActionDetailLabel)
    const eventClassName = block.getFieldValue(ContentConstants.eventClassName)
    const descriptionBelowOption = block.getFieldValue(ContentConstants.descriptionBelowOption)
    const callToActionType = block.getFieldValue(ContentConstants.callToActionType)
    const gaEvents = valueOf(ContentConstants.gaEvents)
    const isCashbackForm = valueOf(ContentConstants.isCashbackForm);
    const campaignCashback = valueOf(ContentConstants.campaignCashback);
    const [isCashbackFormOpen, setIsCashbackFormOpen] = useState(false)
    let textContainerBackgroundColor = null;
    let [windowWidth, setWindowWidth] = useState(ContentConstants.defaultWidth);
    let isPreloadimage = false;
     let mobiledeviceConfiguration = '';
    let preloadBackgroundImage = '';
    let [isPageLoaded, setIsPageLoaded] = useState(false);
    let desktopBackgroundImage = '';
    let mobileBackgroundImage = '';
    let textContainerBackgroundColorRGB = blockState?.textContainerBackgroundColor ? blockState?.textContainerBackgroundColor : valueOf(ContentConstants.textContainerBackgroundColor);
    let textContainerBackgroundOpacity = 1;
    if (blockState?.textContainerBackgroundOpacity) {
        textContainerBackgroundOpacity = blockState?.textContainerBackgroundOpacity;
    }

    if(blockState?.backgroundAsset?.fields?.isPreload){
        isPreloadimage = true;
    }

    if (textContainerBackgroundColorRGB) {
        let colorRgb = getRGBFromHexColor(textContainerBackgroundColorRGB);
        textContainerBackgroundColor = `rgba(${colorRgb.red}, ${colorRgb.green}, ${colorRgb.blue}, ${textContainerBackgroundOpacity})`;
    }

    const textContainerHasPadding = valueOf(ContentConstants.textContainerHasPadding);
    const isTextAlignBottomMobileView = windowWidth <= Constants.desktopViewStart && (className || '').indexOf(ContentConstants.textAlignBottomMobileView) > 0

    useEffect(()=>{     
      setIsPageLoaded(true) 
        window.addEventListener('resize', handleResize)
        handleResize()
        return(()=>{
            window.removeEventListener('resize', handleResize)
        })
        
    },[isPageLoaded])
     const [vocherCode,setVocherCode]=useState(null)
           
    const [sucessModalOpen, setSucessModalOpen] = useState(false);
    
  function handleSucessModal() {
    setSucessModalOpen(!sucessModalOpen)
  }

  function handleResize() {
    setWindowWidth(window.innerWidth);
  }
  const handleGAEventsFunction = (gaEvent) => {
    return gaEvent.map((events) => {
        handleGAEvents(events?.fields?.gaEventCategory, events?.fields?.gaEventAction, events?.fields?.gaEventLabel)
    })
}
  function handleCashbackClick() {
    setIsCashbackFormOpen(!isCashbackFormOpen)
  }
  const getVocherCode = (code) => {
    const responseCode = setVocherCode(code)
    return responseCode;
  }
  
  const renderContentOptions = () => {
    return (
        <div className={`content-options ${contentOptionClassName}`}>
            {contentOptions.map((contentOption, index) => {
                const option = contentOption?.fields
                return (
                    <div key={index} className='content-option-parent'>
                        {option?.subIcon && <Image className='post-purchase-app-section__subicon' image={option?.subIcon} />}
                        <div key={option?.name} href={option?.ctaUrl} className={Label({ label: option?.class })}>
                            {option?.icon && <Image className='post-purchase-app-section__icon' image={option?.icon} />}
                            {option?.title && <p className='post-purchase-app-section__title'>{option?.title}</p>}
                            {option?.description && (
                                <p className={`post-purchase-app-section__description ${option?.class?.fields?.text + '-description'}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option?.description) }} />
                            )}
                            {option?.label && (
                                <Button
                                    className={!gaEvents && eventClassName ? eventClassName : 'event_external_link'}
                                    dataActionDetail={!gaEvents && CtalabelId ? CtalabelId : valueOf(ContentConstants.callToActionLink)}
                                    tag={option?.labelTag}
                                    href={option?.labelUrl}
                                    onClick={() => {
                                        gaEvents && handleGAEventsFunction(gaEvents)
                                    }}
                                    target={option.labelType}
                                    ariaLabel={option?.label}
                                >
                                    {option?.label}
                                </Button>
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const renderAppIcons = () => {
    return (
        <div className='post-purchase-app-section__app-icons'>
            <Image image={appStoreImage} />
            <Image image={googlePlayImage} />
        </div>
    )
}

const renderDescription = () => {
    return (
        <BodyText whiteText={isWhiteText} className={className} styles={{ width: blockState.descriptionWidth ? blockState.descriptionWidth : null }}>
            {valueOf(ContentConstants.description)}
        </BodyText>
    )
}

const renderSubDescription = () => {
    return (
        <div
            style={{
                color: isWhiteText ? 'white' : 'black',
            }}
        >
            <MarkdownText className={'ob-sub-description'} value={valueOf(ContentConstants.subDescription)} />
        </div>
    )
}

const renderCta = () => {
    return (
        <div className='ob-contentBlock-textContent-obLink-container'>
            {isALink ? (
                <ObLink
                    href={`${valueOf(ContentConstants.callToActionLink)}`}
                    className={`event_external_link ${ctaBackgroundColor === ContentConstants.white ? ContentConstants.white : ContentConstants.primaryGrey} ${isWhiteText ? ContentConstants.white : ContentConstants.primaryGrey}`}
                    dataActionDetail={valueOf(ContentConstants.callToActionLink)}
                    icon={ContentConstants.chevronRight}
                >
                    <Icon name={ContentConstants.chevronRight} roundedIcon={isWhiteText ? ContentConstants.white : ContentConstants.blue} aria-hidden='true' />
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(valueOf(ContentConstants.callToActionLabel)) }}></span>
                </ObLink>
            ) : (
                <Button
                    whiteTheme={isWhiteText}
                    borderTheme={!isWhiteText && !isBlueBorder && ctaBackgroundColor === ContentConstants.transparentWithBorder}
                    borderThemeBlue={!isWhiteText && isBlueBorder && ctaBackgroundColor === ContentConstants.transparentWithBorder}
                    invertedTheme={isWhiteText && ctaBackgroundColor === ContentConstants.transparentWithBorder}
                    tag='a'
                    target={callToActionType ? callToActionType : ''}
                    className={valueOf(ContentConstants?.callToActionLink)?.includes('http') ? 'event_outbound_link' : 'event_internal_link'}
                    dataActionDetailPresent={ContentConstants?.callToActionLink ? (valueOf(ContentConstants?.callToActionLink)?.includes('http') ? valueOf(ContentConstants?.callToActionLink) : CtalabelId) : CtalabelId}
                    dataActionDetail={!gaEvents && CtalabelId ? CtalabelId : valueOf(ContentConstants.callToActionLink)}
                    href={valueOf(ContentConstants.callToActionLink)}
                    ariaLabel={
                        valueOf(ContentConstants.callToActionAriaLabel)
                            ? valueOf(ContentConstants.callToActionAriaLabel)
                            : valueOf(ContentConstants.surtitle)
                            ? `${stripHtml(`${valueOf(ContentConstants.surtitle)}`)}-${valueOf(ContentConstants.callToActionLabel)}`
                            : `${valueOf(ContentConstants.callToActionLabel)}`
                    }
                    title={valueOf(ContentConstants.callToActionTitle) ? valueOf(ContentConstants.callToActionTitle) : ''}
                    id={CtalabelId}
                >
                    {valueOf(ContentConstants.callToActionLabel)}
                </Button>
            )}
        </div>
    )
}
    useEffect(() => {
        if (blockState.textFadeIn == false) {
            //If text fadeIn at false show the element at pageLoad
            showContentElement();
        }

        if (block.props.document.fields.devicesConfigurations) {
            setblockState(generateContentblockState());
        }
    }, [isMedium, isXSmall, isXLarge]);
    if (block.props.document.fields.devicesConfigurations) {
            mobiledeviceConfiguration = block.props.document.fields.devicesConfigurations
            let tempTinyMobileDeviceConfiguration = mobiledeviceConfiguration.find(conf=>conf.fields.deviceType === DeviceType.TINY)
            let tempdesktopDeviceConfiguration = mobiledeviceConfiguration.find(conf => conf.fields.deviceType === DeviceType.DESKTOP)
            let tempMobileDeviceConfiguration = mobiledeviceConfiguration.find(conf => conf.fields.deviceType === DeviceType.MOBILE)
            if(typeof tempTinyMobileDeviceConfiguration === 'undefined'){
                mobiledeviceConfiguration = mobiledeviceConfiguration.find(conf => conf.fields.deviceType === DeviceType.MOBILE)
            }else{
                mobiledeviceConfiguration = tempTinyMobileDeviceConfiguration
            }
            if(mobiledeviceConfiguration?.fields?.backgroundAsset?.fields?.isPreload){
                preloadBackgroundImage = mobiledeviceConfiguration?.fields?.backgroundAsset?.fields;
            }
            if (tempdesktopDeviceConfiguration?.fields?.backgroundAsset?.fields) {
              desktopBackgroundImage = tempdesktopDeviceConfiguration?.fields?.backgroundAsset?.fields
            }
            if (tempMobileDeviceConfiguration?.fields?.backgroundAsset?.fields) {
              mobileBackgroundImage = tempMobileDeviceConfiguration?.fields?.backgroundAsset?.fields
            }
        }
    mobiledeviceConfiguration = mobiledeviceConfiguration?.fields

    const hasTextParallax = (blockState.textParallaxConfiguration) ? true : false;

    const textX = getParallaxConfigurationValue(hasTextParallax, ContentConstants.textParallaxConfiguration, ContentConstants.x);
    const textY = getParallaxConfigurationValue(hasTextParallax, ContentConstants.textParallaxConfiguration, ContentConstants.y);

    const hasAssetParallax = (blockState.assetParallaxConfiguration) ? true : false;

    const assetX = getParallaxConfigurationValue(hasAssetParallax, ContentConstants.assetParallaxConfiguration, ContentConstants.x);
    const assetY = getParallaxConfigurationValue(hasAssetParallax, ContentConstants.assetParallaxConfiguration, ContentConstants.y);

    const isWhiteText = (blockState?.textColor) === ContentConstants.White ? true : false;
    const isBlueBorder = className?.includes(ContentConstants.blueBorderBtn);
    const isRenderAppIcons = appStoreImage && googlePlayImage

    const RenderContentWithImageAndTextSeparate = (props) => {
        return (
            <Fragment>
              <Suspense fallback={<Loader/>}>
                <BackgroundWrapper
                    pauseLabel={pauseLabel}
                    playLabel={playLabel}
                    resetLabel={resetLabel}
                    openTranscriptLabel={openTranscriptLabel}
                    closeTranscriptLabel={closeTranscriptLabel}
                    backgroundAsset={blockState.backgroundAsset}
                    sectionOffsetTop={blockState.sectionOffsetTop}
                    backgroundPosition={blockState.backgroundPosition}
                    mainAssetOffsetTop={blockState.mainAssetOffsetTop}
                    mainAssetOffsetRight={blockState.mainAssetOffsetRight}
                    mainAssetOffsetBottom={blockState.mainAssetOffsetBottom}
                    mainAssetOffsetLeft={blockState.mainAssetOffsetLeft}
                    preloadImage={preloadBackgroundImage}
                    desktopBackgroundImage={desktopBackgroundImage}
                    mobileBackgroundImage={mobileBackgroundImage}
                >
                    {blockState.secondaryAsset && (
                        <div className='secondary-asset'>
                            <Image
                                scale={null}
                                image={blockState.secondaryAsset}
                                assetX={assetX}
                                assetY={assetY}
                                hasAssetParallax={hasAssetParallax}
                                className={`
                                ${
                                    isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration?.mainAssetHorizontalAlignment
                                        ? `horizontal-${mobiledeviceConfiguration.mainAssetHorizontalAlignment}`
                                        : blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`
                                }
                                ${
                                    isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration?.mainAssetVerticalAlignment
                                        ? `vertical-${mobiledeviceConfiguration.mainAssetVerticalAlignment}`
                                        : blockState.mainAssetVerticalAlignment && `vertical-${blockState.mainAssetVerticalAlignment}`
                                }
                    `}
                            />
                        </div>
                    )}
                </BackgroundWrapper>
              </Suspense>
                {!isTextAlignBottomMobileView && (
                    <div
                        className={`ob-contentBlock-wrapper ${isPreloadimage && isPageLoaded ? 'show' : show} ${hasDivider ? 'ob-contentBlock-wrapper--has-divider' : ''}`}
                        style={{ padding: blockState.blockPadding ? blockState.blockPadding : null }}
                    >
                        {blockState.textFadeIn !== false && <Waypoint onEnter={_handleWaypointEnter} />}

                        {blockState.mainAsset && blockState.mainAssetBelowOrAboveText === ContentConstants.aboveText && (
                            <div
                                className='ob-contentBlock-mainAsset-container'
                                style={{
                                    width: blockState.mainAssetMaxWidth ? blockState.mainAssetMaxWidth : null,
                                    height: blockState.mainAssetHeight ? blockState.mainAssetHeight : null,
                                    maxHeight: blockState.mainAssetMaxHeight ? blockState.mainAssetMaxHeight : null,
                                    marginRight: blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null,
                                    marginLeft: blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null,
                                    marginTop: blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null,
                                    marginBottom: blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null,
                                }}
                            >
                                <Image
                                    scale={blockState.mainAssetScale}
                                    image={blockState.mainAsset}
                                    assetX={assetX}
                                    assetY={assetY}
                                    hasAssetParallax={hasAssetParallax}
                                    className={`
                            ${
                                isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration?.mainAssetHorizontalAlignment
                                    ? `horizontal-${mobiledeviceConfiguration.mainAssetHorizontalAlignment}`
                                    : blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`
                            }
                            ${
                                isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration?.mainAssetVerticalAlignment
                                    ? `vertical-${mobiledeviceConfiguration.mainAssetVerticalAlignment}`
                                    : blockState.mainAssetVerticalAlignment && `vertical-${blockState.mainAssetVerticalAlignment}`
                            }
                        `}
                                />
                            </div>
                        )}

                        {(valueOf(ContentConstants.description) || title) && (
                            <div
                                className={`ob-contentBlock-textContent
                        ${
                            isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration
                                ? `horizontal-${mobiledeviceConfiguration.textContainerHorizontalAlignment}`
                                : blockState.textContainerHorizontalAlignment && `horizontal-${blockState.textContainerHorizontalAlignment}`
                        }
                        ${
                            isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration
                                ? `vertical-${mobiledeviceConfiguration.textContainerVerticalAlignment}`
                                : blockState.textContainerVerticalAlignment && `vertical-${blockState.textContainerVerticalAlignment}`
                        }               
                    `}
                            >
                                <div
                                    className={`ob-contentBlock-textContent-inner${textContainerHasPadding ? ' hasPadding' : ''} `}
                                    data-width-text={windowWidth}
                                    style={{
                                        marginTop: blockState.textContainerOffset ? blockState.textContainerOffset : null,
                                        marginBottom: blockState.textContainerMarginBottom ? blockState.textContainerMarginBottom : null,
                                        marginLeft: blockState.textContainerMarginLeft ? blockState.textContainerMarginLeft : null,
                                        marginRight: blockState.textContainerMarginRight ? blockState.textContainerMarginRight : null,
                                        backgroundColor: textContainerBackgroundColor ? textContainerBackgroundColor : null,
                                        textAlign: isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration ? mobiledeviceConfiguration.textAlign : blockState.textAlign ? blockState.textAlign : null,
                                        width:
                                            isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration
                                                ? mobiledeviceConfiguration.textContainerWidth
                                                : blockState.textContainerWidth
                                                ? blockState.textContainerWidth
                                                : null,
                                    }}
                                >
                                    <Parallax className='ob-contentBlock-textContent-parallax' x={textX} y={textY} disabled={!hasTextParallax}>
                                        {textsImage && (
                                            <div className='ob-contentBlock-text-image'>
                                                <Image image={textsImage} />
                                            </div>
                                        )}

                                        <p className={'ob-eyebrow'} data-white-text={isWhiteText} data-blue-text={blueEyebrow} dangerouslySetInnerHTML={{ __html: valueOf(ContentConstants.surtitle) }}></p>

                                        <Heading className={titleFontSize} whiteText={isWhiteText} tag={titleLevel ? `h${titleLevel}` : 'h2'} styles={{ width: blockState.titleWidth ? blockState.titleWidth : null }}>
                                            {title}
                                        </Heading>
                                        {!descriptionBelowOption && renderDescription()}
                                        {!descriptionBelowOption && renderSubDescription()}
                                        {!descriptionBelowOption && renderCta()}
                                        {blockState.textFadeIn !== false && <Waypoint onEnter={_handleWaypointEnter} />}
                                        {contentOptions && renderContentOptions()}
                                        {descriptionBelowOption && renderDescription()}
                                        {descriptionBelowOption && renderSubDescription()}
                                        {descriptionBelowOption && renderCta()}
                                    </Parallax>
                                </div>
                                {disclaimerReference && !blockState.mainAsset && renderDisclaimer()}
                                {disclaimerReference && blockState.mainAsset && !isMedium && renderDisclaimer()}
                            </div>
                        )}

                        {blockState.mainAsset && (blockState.mainAssetBelowOrAboveText === ContentConstants.belowText || blockState.mainAssetBelowOrAboveText === '') && (
                            <div
                                className='ob-contentBlock-mainAsset-container'
                                style={{
                                    width: blockState.mainAssetMaxWidth ? blockState.mainAssetMaxWidth : null,
                                    height: blockState.mainAssetHeight ? blockState.mainAssetHeight : null,
                                    maxHeight: blockState.mainAssetMaxHeight ? blockState.mainAssetMaxHeight : null,
                                    marginRight: blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null,
                                    marginLeft: blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null,
                                    marginTop: blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null,
                                    marginBottom: blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null,
                                }}
                            >
                                <Image
                                    scale={blockState.mainAssetScale}
                                    image={blockState.mainAsset}
                                    assetX={assetX}
                                    assetY={assetY}
                                    hasAssetParallax={hasAssetParallax}
                                    className={`
                            ${blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`}
                            ${blockState.mainAssetVerticalAlignment && `vertical-${blockState.mainAssetVerticalAlignment}`}
                    `}
                                />
                            </div>
                        )}
                        {disclaimerReference && isMedium && blockState.mainAsset && renderDisclaimer()}
                    </div>
                )}
            </Fragment>
        )
    }

    const RenderContentWithoutImageAndTextSeparate = (props) => {
        return (
          <Suspense fallback={<Loader/>}>
            <BackgroundWrapper
                pauseLabel={pauseLabel}
                playLabel={playLabel}
                resetLabel={resetLabel}
                openTranscriptLabel={openTranscriptLabel}
                closeTranscriptLabel={closeTranscriptLabel}
                backgroundAsset={blockState.backgroundAsset}
                sectionOffsetTop={blockState.sectionOffsetTop}
                backgroundPosition={blockState.backgroundPosition}
                mainAssetOffsetTop={blockState.mainAssetOffsetTop}
                mainAssetOffsetRight={blockState.mainAssetOffsetRight}
                mainAssetOffsetBottom={blockState.mainAssetOffsetBottom}
                mainAssetOffsetLeft={blockState.mainAssetOffsetLeft}
                preloadImage={preloadBackgroundImage}
                desktopBackgroundImage={desktopBackgroundImage}
                mobileBackgroundImage={mobileBackgroundImage}
            >
                {!isTextAlignBottomMobileView && (
                    <div
                        className={`ob-contentBlock-wrapper ${isPreloadimage && isPageLoaded ? 'show' : show} ${hasDivider ? 'ob-contentBlock-wrapper--has-divider' : ''}`}
                        style={{ padding: blockState.blockPadding ? blockState.blockPadding : null }}
                    >
                        {blockState.textFadeIn !== false && <Waypoint onEnter={_handleWaypointEnter} />}
                        {blockState.mainAsset && blockState.mainAssetBelowOrAboveText === ContentConstants.aboveText && (
                            <div
                                className='ob-contentBlock-mainAsset-container'
                                style={{
                                    width: blockState.mainAssetMaxWidth ? blockState.mainAssetMaxWidth : null,
                                    height: blockState.mainAssetHeight ? blockState.mainAssetHeight : null,
                                    maxHeight: blockState.mainAssetMaxHeight ? blockState.mainAssetMaxHeight : null,
                                    marginRight: blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null,
                                    marginLeft: blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null,
                                    marginTop: blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null,
                                    marginBottom: blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null,
                                }}
                            >
                                <Image
                                    scale={blockState.mainAssetScale}
                                    image={blockState.mainAsset}
                                    assetX={assetX}
                                    assetY={assetY}
                                    hasAssetParallax={hasAssetParallax}
                                    className={`
                                    ${
                                        isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration?.mainAssetHorizontalAlignment
                                            ? `horizontal-${mobiledeviceConfiguration.mainAssetHorizontalAlignment}`
                                            : blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`
                                    }
                                    ${
                                        isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration?.mainAssetVerticalAlignment
                                            ? `vertical-${mobiledeviceConfiguration.mainAssetVerticalAlignment}`
                                            : blockState.mainAssetVerticalAlignment && `vertical-${blockState.mainAssetVerticalAlignment}`
                                    }
                                `}
                                />
                            </div>
                        )}
                        {(valueOf(ContentConstants.description) || title) && (
                            <div
                                className={`ob-contentBlock-textContent
                                ${
                                    isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration
                                        ? `horizontal-${mobiledeviceConfiguration.textContainerHorizontalAlignment}`
                                        : blockState.textContainerHorizontalAlignment && `horizontal-${blockState.textContainerHorizontalAlignment}`
                                }
                                ${
                                    isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration
                                        ? `vertical-${mobiledeviceConfiguration.textContainerVerticalAlignment}`
                                        : blockState.textContainerVerticalAlignment && `vertical-${blockState.textContainerVerticalAlignment}`
                                }               
                            `}
                            >
                                <div
                                    className={`ob-contentBlock-textContent-inner${textContainerHasPadding ? ' hasPadding' : ''} `}
                                    data-width-text={windowWidth}
                                    style={{
                                        marginTop: blockState.textContainerOffset ? blockState.textContainerOffset : null,
                                        marginBottom: blockState.textContainerMarginBottom ? blockState.textContainerMarginBottom : null,
                                        marginLeft: blockState.textContainerMarginLeft ? blockState.textContainerMarginLeft : null,
                                        marginRight: blockState.textContainerMarginRight ? blockState.textContainerMarginRight : null,
                                        backgroundColor: textContainerBackgroundColor ? textContainerBackgroundColor : null,
                                        textAlign: isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration ? mobiledeviceConfiguration.textAlign : blockState.textAlign ? blockState.textAlign : null,
                                        width:
                                            isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration
                                                ? mobiledeviceConfiguration.textContainerWidth
                                                : blockState.textContainerWidth
                                                ? blockState.textContainerWidth
                                                : null,
                                    }}
                                >
                                    <Parallax className='ob-contentBlock-textContent-parallax' x={textX} y={textY} disabled={!hasTextParallax}>
                                        {textsImage && (
                                            <div className='ob-contentBlock-text-image'>
                                                <Image image={textsImage} />
                                            </div>
                                        )}

                                        <p className={'ob-eyebrow'} data-white-text={isWhiteText} data-blue-text={blueEyebrow} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(valueOf(ContentConstants.surtitle)) }}></p>

                                        <Heading className={titleFontSize} whiteText={isWhiteText} tag={titleLevel ? `h${titleLevel}` : 'h2'} styles={{ width: blockState.titleWidth ? blockState.titleWidth : null }}>
                                            {title}
                                        </Heading>
                                        {!descriptionBelowOption && renderDescription()}
                                        {!descriptionBelowOption && renderSubDescription()}
                                        {!descriptionBelowOption && renderCta()}
                                        {blockState.textFadeIn !== false && <Waypoint onEnter={_handleWaypointEnter} />}
                                        {contentOptions && renderContentOptions()}
                                        {descriptionBelowOption && renderDescription()}
                                        {descriptionBelowOption && renderSubDescription()}
                                        {descriptionBelowOption && renderCta()}
                                    </Parallax>
                                </div>
                                {disclaimerReference && !blockState.mainAsset && renderDisclaimer()}
                                {disclaimerReference && blockState.mainAsset && !isMedium && renderDisclaimer()}
                            </div>
                        )}

                        {blockState.mainAsset && (blockState.mainAssetBelowOrAboveText === ContentConstants.belowText || blockState.mainAssetBelowOrAboveText === '') && (
                            <div
                                className='ob-contentBlock-mainAsset-container'
                                style={{
                                    width: blockState.mainAssetMaxWidth ? blockState.mainAssetMaxWidth : null,
                                    height: blockState.mainAssetHeight ? blockState.mainAssetHeight : null,
                                    maxHeight: blockState.mainAssetMaxHeight ? blockState.mainAssetMaxHeight : null,
                                    marginRight: blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null,
                                    marginLeft: blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null,
                                    marginTop: blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null,
                                    marginBottom: blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null,
                                }}
                            >
                                <Image
                                    scale={blockState.mainAssetScale}
                                    image={blockState.mainAsset}
                                    assetX={assetX}
                                    assetY={assetY}
                                    hasAssetParallax={hasAssetParallax}
                                    className={`
                                    ${blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`}
                                    ${blockState.mainAssetVerticalAlignment && `vertical-${blockState.mainAssetVerticalAlignment}`}
                            `}
                                />
                            </div>
                        )}
                        {disclaimerReference && isMedium && blockState.mainAsset && renderDisclaimer()}
                    </div>
                )}
                {blockState.secondaryAsset && (
                    <div className='secondary-asset'>
                        <Image
                            scale={null}
                            image={blockState.secondaryAsset}
                            assetX={assetX}
                            assetY={assetY}
                            hasAssetParallax={hasAssetParallax}
                            className={`
                                            ${
                                                isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration?.mainAssetHorizontalAlignment
                                                    ? `horizontal-${mobiledeviceConfiguration.mainAssetHorizontalAlignment}`
                                                    : blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`
                                            }
                                            ${
                                                isPreloadimage && windowWidth < ContentConstants.defaultWindWidth && mobiledeviceConfiguration?.mainAssetVerticalAlignment
                                                    ? `vertical-${mobiledeviceConfiguration.mainAssetVerticalAlignment}`
                                                    : blockState.mainAssetVerticalAlignment && `vertical-${blockState.mainAssetVerticalAlignment}`
                                            }
                                `}
                        />
                    </div>
                )}
            </BackgroundWrapper>
          </Suspense>
        )
    }

    return (
        <section
            className={`ob-contentBlock content ${className ? className : ''} ${blockState.textShowAnimation} ${blockState.backgroundAsset ? '' : 'no-background'} ${
                blockState.mainAssetBelowOrAboveText === ContentConstants.aboveText ? 'image-above' : ''
            } ${blockState.mainAssetBelowOrAboveText === ContentConstants.belowText ? 'image-below' : ''}`}
            id={anchorId}
            style={{
                backgroundColor: backgroundColor ? backgroundColor : null,
                minHeight: blockState.blockMinHeight ? blockState.blockMinHeight : null,
                marginTop: blockState.blockMarginTop ? blockState.blockMarginTop : null,
                marginBottom: blockState.blockMarginBottom ? blockState.blockMarginBottom : null,
                marginRight: blockState.blockMarginRight ? blockState.blockMarginRight : null,
                marginLeft: blockState.blockMarginLeft ? blockState.blockMarginLeft : null,
            }}
            data-respectimageaspectratio={blockState.respectImageAspectRatio}
        >
            {isContentMobile[1]?.deviceType === 'mobile' && isContentMobile[1]?.isContentImageAndTextSeparate && (isMedium || isSmall || isXSmall)
                ? RenderContentWithImageAndTextSeparate(props)
                : RenderContentWithoutImageAndTextSeparate(props)}
            {isTextAlignBottomMobileView && (
                <div className='text-align-bottom-mobile-view'>
                    <Heading className={titleFontSize} whiteText={isWhiteText} tag={titleLevel ? `h${titleLevel}` : 'h2'} styles={{ width: blockState.titleWidth ? blockState.titleWidth : null }}>
                        {title}
                    </Heading>

                    <BodyText whiteText={isWhiteText} className={className} styles={{ width: blockState.descriptionWidth ? blockState.descriptionWidth : null }}>
                        {valueOf(ContentConstants.description)}
                    </BodyText>
                </div>
            )}
            {isRenderAppIcons && renderAppIcons()}
            {noteText && <p className='ob-note-text'>{noteText}</p>}
    </section>
    )
}

export default mediaQueryHOC(ContentBlock);
// This export is for unit testing (do not remove) :
export const ContentBlockTest = ContentBlock;

ContentBlock.propTypes = {
    isXSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isXLarge: PropTypes.bool,
    className : PropTypes.string,
    name : PropTypes.string
};